<template>
  <div ref="charts" :id="id" :data="data"></div>
</template>

<script>
import { defineComponent } from "vue";
//import axios from "axios";
import * as d3 from "d3";

// 使用d3.schemeCategory10生成不同的颜色
// var color = d3.schemeCategory10;
// var color = d3.scaleOrdinal(d3.schemeCategory10);

export default defineComponent({
  data() {
    return {
      link: {},
    };
  },
  props: {
    data: {
      type: Object,
      default: {
        node: [],
        data: [],
      },
    },
    id: {
      type: String,
      default: "ids"
    }
  },
  watch: {
    data: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.clearChart();
          this.drawBarChart(newVal.node, newVal.data);
        }
      },
      deep: true, //对象内部属性的监听，关键
      immediate: true,
    },
  },
  mounted() {
    this.drawBarChart(this.data.node, this.data.data);
  },
  methods: {
    drawBarChart(nodes, links) {
      var value = 0;
      if (links.length > 0) {
        value = links.length;
      } else {
        value = nodes.length;
      }
      var w =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      var h =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      w = w * 0.28;
      h = h * 0.35;
      // var w = 1560,
      // h = 800;
      const svg = d3.select("#force-container-666").append("svg");
      svg.attr("width", w).attr("height", h);
      // function handleZoom(event) {
      //   svg.selectAll(".node").attr("transform", event.transform);
      // }
      // 绘制节点和链接
      /** 箭头 */
      var marker = svg
        .append("marker")
        //.attr("id", function(d) { return d; })
        .attr("id", "resolved")
        //.attr("markerUnits","strokeWidth")//设置为strokeWidth箭头会随着线的粗细发生变化
        .attr("markerUnits", "userSpaceOnUse")
        .attr("viewBox", "0 -5 10 10") //坐标系的区域
        .attr("refX", 32) //箭头坐标
        .attr("refY", -1)
        .attr("markerWidth", 12) //标识的大小
        .attr("markerHeight", 12)
        .attr("orient", "auto") //绘制方向，可设定为：auto（自动确认方向）和 角度值
        .attr("stroke-width", 2) //箭头宽度
        .append("path")
        .attr("d", "M0,-5L10,0L0,5") //箭头的路径
        .attr("fill", "gray"); //箭头颜色
      //设置连接线
      var link = svg
        .selectAll(".link")
        .data(links)
        .enter()
        .append("line")
        .style("stroke", "grey")
        .style("stroke-width", 0.5)
        .style("stroke", "#43F2D3")
        .style("pointer-events", "none")
        .attr("marker-end", "url(#resolved)"); //根据箭头标记的id号标记箭头
      this.link = link;
      // 根据数据点的数量动态计算节点半径的大小
      //  var nodeRadiusScale = d3.scaleSqrt().domain([2, d3.max(nodes.length, links.length)]) // 根据节点值定义域
      // .range([15, 20]); // 定义半径范围
      var nodeRadiusScale = d3
        .scaleSqrt()
        .domain([0, nodes.length]) // 根据节点值定义域
        .range([10, 30]); // 定义半径范围
      // 设置circle node
      var node = svg
        .selectAll(".node")
        .data(nodes)
        .enter()
        .append("circle")
        .style("fill", (d) => d.color)
        .style("stroke", (d) => d.color)
        .style("stroke-opacity", 0.7)
        .style("stroke-width", "20px")
        .attr("r", nodeRadiusScale(nodes.length)) // 使用计算出的半径) //设置圆圈半径
        .on("dblclick", (event, node) => this.getSomeNodeLinks(event, node))
        .on("click", (event, node) => this.getCaterogy(event, node))
        //将当前选中的元素传到drag函数中，使顶点可以被拖动
        .call(drag());
      var forceSimulation = d3
        .forceSimulation()
        .force("link", d3.forceLink())
        // 整个实例中心
        .force("center", d3.forceCenter(w / 1.8, h / 2))
        // 引力
        .force("charge", d3.forceManyBody().strength(40))
        // 碰撞力 防止节点重叠
        .force("collide", d3.forceCollide().radius(40).iterations(2))
        // .force("collide", d3.forceCollide().radius(60).iterations(-500))
        .force("manyBody", d3.forceManyBody().strength(33)); // 增加节点之间的力
      // .force("charge", d3.forceManyBody().strength(55));
      forceSimulation.nodes(nodes).on("tick"); //指时间间隔，隔一段时间刷新一次画面
      forceSimulation.force("link").links(links).distance(180);

      node.on("contextmenu", (event, d) => {
        // 阻止默认的右键点击弹出菜单行为
        event.preventDefault();
        // 右键弹出属性
        this.handlerNodeClick(event, d);
      });
      // forceSimulation.on("tick", () => {
      //   link
      //     .attr("x1", (d) => d.source.x)
      //     .attr("y1", (d) => d.source.y)
      //     .attr("x2", (d) => d.target.x)
      //     .attr("y2", (d) => d.target.y);
      //   node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
      //   edges_text
      //     .attr("x", (d) => (d.source.x + d.target.x) / 2)
      //     .attr("y", (d) => (d.source.y + d.target.y) / 2);
      //   texts.attr("x", (d) => d.x).attr("y", (d) => d.y);
      // });
      forceSimulation.on("tick", function () {
        //对于每一个时间间隔
        // if(nodes.length>50){
        //更新连线坐标
        link
          .attr("x1", function (d) {
            return validateXY(d.source.x, "x");
          })
          .attr("y1", function (d) {
            return validateXY(d.source.y, "y");
          })
          .attr("x2", function (d) {
            return validateXY(d.target.x, "x");
          })
          .attr("y2", function (d) {
            return validateXY(d.target.y, "y");
          });

        //更新节点坐标
        node
          .attr("cx", function (d) {
            return validateXY(d.x, "x");
          })
          .attr("cy", function (d) {
            return validateXY(d.y, "y");
          });

        //更新文字坐标
        edges_text
          .attr("x", function (d) {
            return validateXY((d.source.x + d.target.x) / 2, "x");
          })
          .attr("y", function (d) {
            return validateXY((d.source.y + d.target.y) / 2, "y");
          });
        texts
          .attr("x", function (d) {
            return validateXY(d.x, "x");
          })
          .attr("y", function (d) {
            return validateXY(d.y, "y");
          });
        // } else {
        //   //更新连线坐标
        //        link.attr("x1",function(d){ return validateXY(d.source.x,'x'); })
        //                .attr("y1",function(d){ return validateXY(d.source.y,'y'); })
        //                .attr("x2",function(d){ return validateXY(d.target.x,'x'); })
        //                .attr("y2",function(d){ return validateXY(d.target.y,'y'); });

        //        //更新节点坐标
        //        node.attr("cx",function(d){ return validateXY(d.x,'x'); })
        //                .attr("cy",function(d){ return validateXY(d.y,'y'); });

        //        //更新文字坐标
        //        edges_text.attr("x", function(d){ return validateXY(d.x,'x'); })
        //            .attr("y", function(d){ return validateXY(d.y,'y'); });
        //             texts.attr("x", function(d){ return validateXY(d.x,'x'); }).attr("y", function(d){ return validateXY(d.y,'y'); });
        // }
      });
      //校验坐标是否在范围内，20为圆的半径
      function validateXY(val, type) {
        var r = 20;
        if (val < r) return r;
        if (type == "x") {
          if (val > w - r) return w - r;
        } else {
          if (val > h - r) return h - r;
        }
        return val;
      }
      function drag() {
        function dragstarted(event, d) {
          if (!event.active) forceSimulation.alphaTarget(0.1).restart();
          d.fx = d.x;
          d.fy = d.y;
        }
        function dragged(event, d) {
          d.fx = event.x;
          d.fy = event.y;
        }
        function dragended(event, d) {
          if (!event.active) forceSimulation.alphaTarget(0);
          d.fx = null;
          d.fy = null;
        }
        return d3
          .drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended);
      }
      var edges_text = svg
        .selectAll(".linetext")
        .data(links)
        .enter()
        .append("text")
        .attr("class", "linetext")
        .text((d) => d.relation)
        .style("pointer-events", "none")
        .style("stroke", "#43F2D3")
        .style("font-size", 12);

      var texts = svg
        .selectAll(".forceText")
        .data(nodes)
        //返回缺失元素的占位对象（placeholder），指向绑定的数据中比选定元素集多出的一部分元素。
        .enter()
        .append("text")
        .attr("dy", ".35em")
        .attr("text-anchor", "middle") //在圆圈中加上数据
        .style("fill", "gray")
        .style("stroke", "#000")
        .style("pointer-events", "none")
        .style("font-size", 16)
        .text((d) => d.name);
    },
    clearChart() {
      d3.select("#force-container-666").selectAll("*").remove();
    },
    handlerNodeClick(event, node) {
      //单击时让连接线加粗
      this.link.style("stroke-width", function (line) {
        if (line.source.name == node.name || line.target.name == node.name) {
          return 2;
        } else {
          return 0.5;
        }
      });
      if (node.category == "transformer") {
        this.$emit("getOpen", event, node);
      } else if (
        node.category == "tf_tiexin" ||
        node.category == "tf_dga" ||
        node.category == "tf_jufang" ||
        node.category == "tf_youwen"
      ) {
        // 具体时间
        this.$emit("getOpenTime", node);
      } else {
        // 其他情况 弹出属性边框
        this.$emit("getOpenX", event, node);
      }
    },
    getSomeNodeLinks(event, node) {
      //单击时加载此节点相关的节点以及联系
      this.link.style("stroke-width", 0.5);
      this.$emit("getSomeNodes", node);
    },
    getCaterogy(event, node) {
      //单击时加载此节点相关的节点以及联系
      //单击时让连接线加粗
      this.link.style("stroke-width", function (line) {
        if (line.source.name == node.name || line.target.name == node.name) {
          return 2;
        } else {
          return 0.5;
        }
      });
    },
  },
});
</script>
