<template>
  <div class="fault-content-4">
    <div class="content-1 display-flex">
      <div class="left">
        <!--图片-->
        <div class="display-flex selectX">
          <span class="text-s">设备名称</span>
          <el-select v-model="value" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="display-flex images">
          <el-image :src="require('../image/byq1.png')"></el-image>
        </div>
        <div class="status position1">
          <div class="circle"></div>
          <div class="text">过热</div>
        </div>
        <div class="status position2">
          <div class="circle"></div>
          <div class="text">绕组短路</div>
        </div>
        <div class="status position3">
          <div class="circle"></div>
          <div class="text">绝缘老化</div>
        </div>
        <div class="display-flex times">
          <div class="time">{{ time }}</div>
        </div>
        <div class="infos">
          <div class="display-flex texts">设备点位故障详情</div>
          <div class="display-flex textInfo">
            <div class="text-s1">
              型号：AMS-12<br />
              出厂序号：TE-09-OCH8<br />
              制造日期：2013年12月<br />
              额定电压：12kV<br />
              额定频率：50Hz<br />
            </div>
            <div class="text-s2">
              额定电流：4000A<br />
              其他铭牌信息：其他信息<br />
              其他铭牌信息：其他信息<br />
              其他铭牌信息：其他信息<br />
              其他铭牌信息：其他信息<br />
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <div class="weather">
          <div class="display-flex texts">天气信息</div>
          <div class="display-flex">
            <span style="flex: 1; color: rgba(255, 138, 42, 1); font-size: 18px"
              >历史温度曲线</span
            ><span
              style="flex: 1; color: rgba(139, 254, 143, 1); font-size: 18px"
              >正常天气/极端天气</span
            >
          </div>
          <div class="chart">
            <chart
              :cdata="weatherOptions"
              :id="'weather_fenbu'"
              :height="'calc(16vh)'"
            />
          </div>
          <div class="display-flex infossss">
            <div class="contentS">
              <div class="name">
                <el-image
                  style="width: 40px; height: 40px"
                  :src="require('../image/guangzhao.png')"
                ></el-image
                >光照
              </div>
              <div class="digital">218.0 lx</div>
            </div>
            <div class="contentS">
              <div class="name">
                <el-image
                  style="width: 40px; height: 40px"
                  :src="require('../image/wind.png')"
                ></el-image
                >风速
              </div>
              <div class="digital">7.2 m/s</div>
            </div>
            <div class="contentS">
              <div class="name">
                <el-image
                  style="width: 40px; height: 40px"
                  :src="require('../image/temperature.png')"
                ></el-image
                >温度
              </div>
              <div class="digital">28.8 ℃</div>
            </div>
            <div class="contentS">
              <div class="name">
                <el-image
                  style="width: 40px; height: 40px"
                  :src="require('../image/wet.png')"
                ></el-image
                >湿度
              </div>
              <div class="digital">74.5 %</div>
            </div>
          </div>
        </div>
        <div class="zy">
          <div class="display-flex texts">综合研判</div>
          <div class="display-flex textInfo">
            结论性研判结果
          </div>
          <div class="border-container">
            <BarChart
              :id="'force-container'"
              :data="tupuData"
              @getOpenX="openShuXing"
              @getSomenode="getSomenodeX"
            />
          </div>
        </div>
      </div>
      <div class="right">
        <div class="weather">
          <div class="display-flex texts">负荷信息</div>
          <div class="display-flex">
            <span style="flex: 1; color: rgba(255, 138, 42, 1); font-size: 18px"
              >历史负荷曲线</span
            >
          </div>
          <div class="chart">
            <chart
              :cdata="fuheOptions"
              :id="'fuhe_fenbu'"
              :height="'calc(14vh)'"
            />
          </div>
          <div class="display-flex">
            <span
              style="flex: 1; color: rgba(187, 222, 251, 1); font-size: 18px"
              >负荷预测信息： xxx</span
            >
          </div>
          <div class="display-flex infossss">
            <div class="contentS">
              <div class="name">
                <el-image
                  style="width: 40px; height: 40px"
                  :src="require('../image/guangzhao.png')"
                ></el-image
                >电流
              </div>
              <div class="digital">218.0 A</div>
            </div>
            <div class="contentS">
              <div class="name">
                <el-image
                  style="width: 40px; height: 40px"
                  :src="require('../image/wind.png')"
                ></el-image
                >电压
              </div>
              <div class="digital">7.2 kV</div>
            </div>
            <div class="contentS">
              <div class="name">
                <el-image
                  style="width: 40px; height: 40px"
                  :src="require('../image/temperature.png')"
                ></el-image
                >功率
              </div>
              <div class="digital">28.8 kPW</div>
            </div>
          </div>
        </div>
        <div class="zy">
          <div class="display-flex texts">检修与措施方案</div>
          <div class="display-flex textInfo">
            处理建议：针对检测到的故障或异常，提供具体的处理建议或解决方案。<br />
            优化运行建议：根据实时监测数据和负荷预测信息，给出优化变压器运行状态的建议或策略。
          </div>
          <div class="border-container">
            <BarChart1
              :id="'force-container-666'"
              :data="tupuData1"
              @getOpenX="openShuXing"
              @getSomenode="getSomenodeX"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="contentX display-flex">
      该变压器当前运行状态正常，但需继续关注天气变化对油温的影响，并定期进行负载、振动、局放和温度等监测工作，确保变压器长期安全稳定运行。
    </div>
  </div>
</template>

<script>
import chart from "./echarts.vue";
import BarChart from "./force.vue";
import BarChart1 from "./force1.vue";
import { getTupuJson } from "@/api/index";
export default {
  components: { BarChart, chart, BarChart1 },
  data() {
    return {
      time: "2024-09-24 12:00:00",
      weatherOptions: {},
      fuheOptions: {},
      options: [
        {
          value: "1",
          label: "设备1",
        },
        {
          value: "2",
          label: "设备2",
        },
        {
          value: "3",
          label: "设备3",
        },
        {
          value: "4",
          label: "设备4",
        },
        {
          value: "5",
          label: "设备5",
        },
      ],
      value: "1",
      // tupuDataDemo: {
      //   node: [
      //     { name: "1#变压器", category: "变压器", color: "#015971" },
      //     { name: "2#变压器", category: "变压器", color: "#00FF71" },
      //     { name: "黄港光伏电站", category: "变电站", color: "#743A49" },
      //     { name: "2020_dga", category: "年份", color: "#fa2dc1" },
      //     { name: "悬浮放电", category: "故障类型", color: "#00A2E8" },
      //     { name: "暴雨", category: "气象数据", shuxing: { t: "33℃" } },
      //     { name: "局放数据", category: "案例_局放", color: "#fa2dc1" },
      //     { name: "铁芯接地电流", category: "案例_电流", color: "#2Af4f4" },
      //     { name: "油温数据", category: "案例_油温", color: "#1Af4f4" },
      //     { name: "dga数据", category: "案例_dga", color: "#2Af4f4" },
      //     { name: "2020_jf", category: "年份", color: "#2Af4f4" },
      //     { name: "2020_yw", category: "年份", color: "#2Af4f4" },
      //     { name: "2020_dl", category: "年份", color: "#2Af4f4" },
      //     { name: "铁芯接地", category: "电流数据", color: "#2Af4f4" },
      //     { name: "低能放电", category: "案例", color: "#2Af4f4" },
      //     { name: "7", category: "月份", color: "#2Af4f4" },
      //     { name: "8", category: "月份", color: "#2Af4f4" },
      //     { name: "8-10", category: "日", color: "#2Af4f4" },
      //     { name: "8-11", category: "日", color: "#2Af4f4" },
      //   ],
      //   // data: []
      //   data: [
      //     {
      //       source: "1#变压器",
      //       target: "黄港光伏电站",
      //       relation: "belongto",
      //       value: 3,
      //     },
      //     {
      //       source: "1#变压器",
      //       target: "2020_dga",
      //       relation: "jufang_data",
      //       value: 3,
      //     },
      //     {
      //       source: "1#变压器",
      //       target: "2020_jf",
      //       relation: "dga_data",
      //       value: 3,
      //     },
      //     {
      //       source: "1#变压器",
      //       target: "2020_yw",
      //       relation: "tiexin_data",
      //       value: 3,
      //     },
      //     {
      //       source: "1#变压器",
      //       target: "2020_dl",
      //       relation: "youwen_data",
      //       value: 3,
      //     },
      //     {
      //       source: "低能放电",
      //       target: "1#变压器",
      //       relation: "occurred_in",
      //       value: 3,
      //     },
      //     {
      //       source: "铁芯接地",
      //       target: "1#变压器",
      //       relation: "occurred_in",
      //       value: 3,
      //     },
      //     {
      //       source: "悬浮放电",
      //       target: "1#变压器",
      //       relation: "occurred_in",
      //       value: 3,
      //     },
      //     {
      //       source: "2#变压器",
      //       target: "黄港光伏电站",
      //       relation: "belongto",
      //       value: 3,
      //     },
      //     {
      //       source: "悬浮放电",
      //       target: "暴雨",
      //       relation: "caused_by",
      //       value: 3,
      //     },
      //     {
      //       source: "悬浮放电",
      //       target: "油温数据",
      //       relation: "jufang_data",
      //       value: 3,
      //     },
      //     {
      //       source: "悬浮放电",
      //       target: "局放数据",
      //       relation: "youwen_data",
      //       value: 3,
      //     },
      //     {
      //       source: "悬浮放电",
      //       target: "铁芯接地电流",
      //       relation: "tiexin_data",
      //       value: 3,
      //     },
      //     {
      //       source: "悬浮放电",
      //       target: "dga数据",
      //       relation: "dga_data",
      //       value: 3,
      //     },
      //     { source: "7", target: "2020_dga", relation: "belongto", value: 1 },
      //     { source: "8", target: "2020_dga", relation: "belongto", value: 3 },
      //     { source: "8-10", target: "8", relation: "belongto", value: 3 },
      //     { source: "8-11", target: "8", relation: "belongto", value: 3 },
      //   ],
      // },
      tupuDataDemo: {
        node: [
          { name: "1#变压器", category: "变压器", color: "#015971" },
          { name: "温度状态", category: "温度影响", color: "#00A2E8" },
          { name: "绝缘状态", category: "绝缘影响", shuxing: { t: "33℃" } },
          { name: "振动状态", category: "振动影响", color: "#fa2dc1" },
          { name: "负载状态", category: "负载影响", color: "#2Af4f4" },
          { name: "天气影响", category: "天气影响", color: "#1Af4f4" },
        ],
        // data: []
        data: [
          {
            source: "1#变压器",
            target: "温度状态",
            relation: "温度影响",
            value: 3,
          },
          {
            source: "1#变压器",
            target: "绝缘状态",
            relation: "绝缘影响",
            value: 3,
          },
          {
            source: "1#变压器",
            target: "振动状态",
            relation: "振动影响",
            value: 3,
          },
          {
            source: "1#变压器",
            target: "负载状态",
            relation: "负载因素",
            value: 3,
          },
          {
            source: "1#变压器",
            target: "天气影响",
            relation: "天气因素",
            value: 3,
          },
        ],
      },
      tupuDataDemo1: {
        node: [
          { name: "故障处理建议", category: "故障", color: "#015971" },
          { name: "优化运行建议", category: "建议", color: "#00A2E8" },
        ],
        // data: []
        data: [
          {
            source: "故障处理建议",
            target: "优化运行建议",
            relation: "建议",
            value: 3,
          },
        ],
      },
      tupuData: {
        node: [],
        data: [],
      },
      tupuData1: {
        node: [],
        data: [],
      },
      centerDialogVisible1: false,
      newnodeopenObj: {},
    };
  },
  methods: {
    setData() {
      this.setWeatherOptions();
      this.setFuheOptions();
      this.setNodeOptions();
    },
    setWeatherOptions() {
      var data = {
        city: ["09-01", "09-02", "09-03", "09-04", "09-05", "09-06"],
        num: ["40", "60", "22", "85", "50", "40"],
      };
      this.weatherOptions = {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(255,255,255,0)", // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: "rgba(255,255,255,1)", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(255,255,255,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        },
        grid: {
          top: "18%",
          left: "15%",
          right: "5%",
          bottom: "25%",
          // containLabel: true
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: true,
            axisLine: {
              //坐标轴轴线相关设置。数学上的x轴
              show: true,
              lineStyle: {
                color: 'color:"#D5CD30"',
              },
            },
            axisLabel: {
              //坐标轴刻度标签的相关设置
              textStyle: {
                color: "#24c4ff",
                margin: 15,
              },
              formatter: function (data) {
                return data;
              },
            },
            axisTick: {
              show: false,
            },
            data: data.city,
          },
        ],
        yAxis: [
          {
            min: 0,
            max: 100,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#092b5d",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#D5CD30",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#24c4ff",
              },
              formatter: function (value) {
                if (value === 0) {
                  return value;
                }
                return value + "℃";
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "温度",
            type: "line",
            symbol: "circle", // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            smooth: false,
            symbolSize: 8,
            lineStyle: {
              normal: {
                color: "#D5CD30", // 线条颜色
              },
              borderColor: "rgba(0,0,0,.4)",
            },
            itemStyle: {
              color: "rgba(14,30,73,1)",
              borderColor: "#D5CD30",
              borderWidth: 2,
            },
            label: {
              normal: {
                show: true,
                position: "top",
                formatter: [" {a|{c}℃}"].join(","),
                rich: {
                  a: {
                    color: "#fff",
                    align: "center",
                  },
                },
              },
            },
            tooltip: {
              show: true,
            },
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(124, 128, 244,.3)",
                    },
                    {
                      offset: 1,
                      color: "rgba(124, 128, 244, 0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(53,142,215, 0.9)", //阴影颜色
                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
            data: data.num,
          },
        ],
      };
    },
    setFuheOptions() {
      var data = {
        city: ["09-01", "09-02", "09-03", "09-04", "09-05", "09-06"],
        num: ["40", "60", "22", "85", "50", "40"],
      };
      this.fuheOptions = {
        backgroundColor: "transparent",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(255,255,255,0)", // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: "rgba(255,255,255,1)", // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(255,255,255,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        },
        grid: {
          top: "18%",
          left: "15%",
          right: "5%",
          bottom: "25%",
          // containLabel: true
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: true,
            axisLine: {
              //坐标轴轴线相关设置。数学上的x轴
              show: true,
              lineStyle: {
                color: 'color:"#D5CD30"',
              },
            },
            axisLabel: {
              //坐标轴刻度标签的相关设置
              textStyle: {
                color: "#24c4ff",
                margin: 15,
              },
              formatter: function (data) {
                return data;
              },
            },
            axisTick: {
              show: false,
            },
            data: data.city,
          },
        ],
        yAxis: [
          {
            min: 0,
            max: 100,
            splitLine: {
              show: true,
              lineStyle: {
                color: "#092b5d",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#D5CD30",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#24c4ff",
              },
              formatter: function (value) {
                if (value === 0) {
                  return value;
                }
                return value + "kPW";
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: "温度",
            type: "line",
            symbol: "circle", // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            smooth: false,
            symbolSize: 8,
            lineStyle: {
              normal: {
                color: "#D5CD30", // 线条颜色
              },
              borderColor: "rgba(0,0,0,.4)",
            },
            itemStyle: {
              color: "rgba(14,30,73,1)",
              borderColor: "#D5CD30",
              borderWidth: 2,
            },
            label: {
              normal: {
                show: true,
                position: "top",
                formatter: [" {a|{c}kPW}"].join(","),
                rich: {
                  a: {
                    color: "#fff",
                    align: "center",
                  },
                },
              },
            },
            tooltip: {
              show: true,
            },
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(124, 128, 244,.3)",
                    },
                    {
                      offset: 1,
                      color: "rgba(124, 128, 244, 0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(53,142,215, 0.9)", //阴影颜色
                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
            data: data.num,
          },
        ],
      };
    },
    openShuXing(event, newValue) {
      this.centerDialogVisible1 = true;
      if ("image_1" in newValue) {
        // 将ArrayBuffer转换为Base64编码的字符串
        const buffer = new Uint8Array(newValue.image_1);
        const base64String = btoa(
          buffer.reduce((data, byte) => data + String.fromCharCode(byte), "")
        );
        let value = "data:image/png;base64," + base64String;
        newValue.image_1 = value;
      } else {
        this.newnodeopenObj = newValue;
      }
    },
    // 单击时加载此节点相关的节点及关系
    getSomenodeX(newValue) {
      console.log(newValue);
      // this.paramsLuyou = this.paramsLuyou + ">" + newValue.name;
      // getTupuDataById({ node_id: newValue.id }).then((res) => {
      //   if (res.code == 200) {
      //     console.log(res);
      //     this.tupuDataDemo.node = [];
      //     this.tupuDataDemo.data = [];
      //     if (res.node.length > 0) {
      //       // 记录下节点路径

      //       // let dataNode = this.currentTupuData.node.concat(res.node);
      //       // let linkArray = this.currentTupuData.data.concat(res.data);
      //       // this.tupuDataDemo.node = this.uniqueObjects(dataNode, "id");
      //       this.tupuDataDemo.data = res.data;
      //       this.tupuDataDemo.node = res.node;
      //       this.currentTupuData.node = res.node;
      //       this.currentTupuData.data = res.data;
      //       this.setValue(this.tupuDataDemo);
      //     } else {
      //       // this.$message.info(
      //       //   "此节点下无数据！这里有问题，至少应该包含它本身"
      //       // );
      //       this.tupuDataDemo.node = this.currentTupuData.node;
      //       this.tupuDataDemo.data = this.currentTupuData.data;
      //       this.setValue(this.tupuDataDemo);
      //     }
      //   } else {
      //     this.$message.error(res.error);
      //   }
      // });
    },
    setNodeOptions() {
      // getTupuJson().then((res) => {
      //   if (res.node.length > 0) {
      //     this.tupuDataDemo.node = res.node;
      //     this.tupuDataDemo.data = res.data;
      //     this.total_size = res.node.length;
      //     this.total_link_size = res.data.length;
      //     this.setValue(this.tupuDataDemo);
      //   }
      // });
      const result = this.setValue(this.tupuDataDemo);
      const result1 = this.setValue(this.tupuDataDemo1);
      this.tupuData.node = result.node;
      this.tupuData.data = result.data;
      this.tupuData1.node = result1.node;
      this.tupuData1.data = result1.data;
    },
    // 把后端数据格式化存储起来
    setValue(data) {
      // 颜色
      let color = [
        "#B5E61D",
        "#B090F0",
        "#E6B01D",
        "#BFBEC9",
        "#C3C3C3",
        "#FF7F27",
        "#7092BE",
        "#C8BFE7",
        "#B97A57",
        "#51A1C2",
        "#C3C3C3",
        "#8C7186",
        "#01F971",
        "#F5D602",
        "#73D551",
        "#3DB6FC",
        "#FFCC00",
        "#B090F0",
        "#F020F0",
        "#330F3",
        "#A0A0A0",
        "#B0C0F0",
      ];
      // 节点列表  data表示联系， node表示节点
      // 创建一个映射，将name值映射到节点的索引
      let nodeMap = {}; // 局部变量，全局变量会出问题
      let node_1 = [];
      let link_1 = [];
      if (data.node.length > 0) {
        data.node.forEach(function (v, i) {
          nodeMap[v.name] = i;
        });
        let i = 0,
          j = 0;
        data.data.forEach((v, index) => {
          i = nodeMap[v.source];
          j = nodeMap[v.target];
          link_1.push({
            source: i,
            target: j,
            relation: v.relation,
            color: color[index],
          });
        });
        data.node.forEach((v, index) => {
          node_1.push({
            id: v.id,
            name: v.name,
            category: v.category ? v.category : v.name,
            color: color[index],
            shuxing: v.n,
          });
        });
      }
      return { node: node_1, data: link_1 };
      this.tupuData.node = node_1;
      this.tupuData.data = link_1;
      // this.linkTypeList = this.getLinkTypeList(this.tupuData.data);
    },
    // 得到专属颜色，颜色会保持一致
    getColorByCategory(arr, category) {
      const obj = arr.find((item) => item.category === category);
      return obj ? obj.color : null;
    },
  },
  created() {
    this.setData();
  },
};
</script>

<style scoped lang="scss">
.fault-content-4 {
  width: 95%;
  margin: 0 auto;
  height: calc(100vh - 160px);
  color: rgb(113, 203, 255);

  .display-flex {
    display: flex;
  }
  .border-container {
    position: relative;
    overflow: hidden;
  }
  .text-s {
    font-size: 20px;
    color: rgb(194, 213, 238);
    font-weight: 700;
    margin: 5px 10px;
    line-height: 50px;
  }
  .selectX {
    margin-left: 20%;
  }
  .texts {
    color: rgb(52, 250, 240);
    font-size: 22px;
  }
  .content-1 {
    display: grid;
    height: 96%;
    grid-template-columns: 1fr 1.2fr 1fr;
    .left {
      .images {
        width: 80%;
        height: calc(42vh);
        margin: 0 auto;
        margin-top: 2%;
      }
      .status {
        position: relative;
        width: 150px;
        height: 53px;
        border-radius: 10px;
        background-color: rgba(159, 0, 0, 0.46);
        display: flex;
        .circle {
          width: 20px; /* 圆的宽度 */
          height: 20px; /* 圆的高度 */
          margin: 16px 0 0 10px;
          background-color: rgba(183, 28, 28, 1); /* 圆的背景颜色 */
          border-radius: 50%; /* 使边角圆滑，形成圆形 */
        }
        .text {
          color: #fff;
          font-size: 24px;
          margin: 8px 0 0 10px;
        }
      }
      @keyframes flash {
        0% {
          background-color: rgba(254, 91, 3, 0.67);
        } /* 红色 */
        50% {
          background-color: rgba(159, 0, 0, 0.46);
        } /* 绿色 */
        100% {
          background-color: rgba(254, 91, 3, 0.67);
        } /* 蓝色 */
      }
      .position1 {
        position: absolute;
        top: 18%;
        left: 4%;
        animation: flash 1s infinite;
      }
      .position2 {
        position: absolute;
        top: 8%;
        left: 10%;
      }
      .position3 {
        position: absolute;
        top: 15%;
        left: 18%;
      }
      .times {
        margin-top: 1%;
        width: 80%;
        height: 50px;
        border: none;
        margin: 0 auto;
        color: #fff;
        background-image: linear-gradient(30deg, #0400ff, #4ce3f7);
        border-radius: 50px;
        background-size: 100% auto;
        font-family: inherit;
        font-size: 17px;
        padding: 0.6em 1.5em;
        .time {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          align-items: center;
          gap: 15px;
          padding: 15px 43px;
          border-radius: 50px;
          font-size: 24px;
          font-weight: 700;
        }
      }
      .infos {
        border: 1px solid #0ebdd4;
        flex-wrap: wrap;
        width: 90%;
        margin: 0 auto;
        margin-top: 5%;
        height: calc(20vh);
        .textInfo {
          color: rgba(226, 222, 222, 1);
          font-size: 18px;
          font-weight: 500;
          margin-top: 20px;
          display: flex;
          .text-s1 {
            margin-left: 10px;
            flex: 1;
          }
          .text-s2 {
            flex: 1;
          }
        }
      }
    }
    .center {
      display: grid;
      height: 96%;
      grid-template-rows: 1fr 1fr;
      .weather {
        border: 1px solid rgba(27, 217, 247, 1);
        padding: 10px;
        height: calc(37vh);
        .chart {
          width: 100%;
          height: calc(16vh);
        }
        .infossss {
          border: 1px solid rgba(30, 150, 255, 1);
          background-color: rgba(0, 133, 200, 0.59);
          flex-wrap: wrap;
          width: 90%;
          margin: 1% auto;
          height: calc(12vh);
          display: flex;
          .contentS {
            flex: 1;
            display: grid;
            grid-template-rows: 1fr 1fr;
            .name {
              display: flex;
              font-size: 28px;
              height: 50%;
              color: #fff;
              margin-top: 10px;
              margin-left: 10px;
            }
            .digital {
              display: flex;
              margin-left: 24px;
              margin-top: 12px;
              font-size: 24px;
              height: 50%;
              color: #fff;
            }
          }
        }
      }
      .zy {
        margin-top: 10px;
        height: calc(40vh);
        border: 1px solid rgba(27, 217, 247, 1);
        padding: 10px;
      }
    }
    .right {
      display: grid;
      height: 96%;
      margin-left: 10px;
      grid-template-rows: 1fr 1fr;
      .weather {
        border: 1px solid rgba(27, 217, 247, 1);
        padding: 10px;
        height: calc(37vh);
        .chart {
          width: 100%;
          height: calc(14vh);
        }
        .infossss {
          border: 1px solid rgba(30, 150, 255, 1);
          background-color: rgba(0, 133, 200, 0.59);
          flex-wrap: wrap;
          width: 90%;
          margin: 1% auto;
          height: calc(12vh);
          display: flex;
          .contentS {
            flex: 1;
            display: grid;
            grid-template-rows: 1fr 1fr;
            .name {
              display: flex;
              font-size: 28px;
              height: 50%;
              color: #fff;
              margin-top: 10px;
              margin-left: 10px;
            }
            .digital {
              display: flex;
              margin-left: 24px;
              margin-top: 12px;
              font-size: 24px;
              height: 50%;
              color: #fff;
            }
          }
        }
      }
      .zy {
        margin-top: 10px;
        height: calc(40vh);
        border: 1px solid rgba(27, 217, 247, 1);
        padding: 10px;
      }
    }
    .textInfo {
          color: rgba(226, 222, 222, 1);
          font-size: 12px;
          font-weight: 500;
    }
  }
  .contentX {
    height: 50px;
    background-color: rgba(255, 249, 196, 1);
    border: rgba(30, 150, 255, 1) 2px solid;
    font-size: 26px;
    font-weight: 700;
    color: rgba(230, 81, 0, 1);
  }
  /deep/ .el-select {
    margin-top: 10px;
  }
}
</style>